"use client";

import Image from "next/image";
import Link from "next/link";

import { buttonVariants } from "@/components/ui/button";

export default function ErrorPage() {
  return (
    <div className="m-16 flex h-full flex-col items-center justify-center space-y-6 text-center">
      <h1 className="font-semibold text-5xl">Error</h1>
      <Image src={"/img/404.png"} alt="Bill error" width={200} height={284} />

      <p>
        Oh no something has gone wrong. Please refresh the page to try again or
        ...
      </p>
      <Link className={buttonVariants()} href="/">
        Return Home
      </Link>
    </div>
  );
}
